import actionCreatorFactory from 'typescript-fsa'
import { CommonResponse } from '~types/api'
import { AuthInitResponse } from '~types/auth'
import { createRequestThunk } from '~utils/store'
import AuthService from '~services/auth'

const actionCreator = actionCreatorFactory('@@msnslot-ag/auth')
export const requestAuthInitActions = actionCreator.async<boolean, AuthInitResponse, CommonResponse>('requestAuthInit')

export const requestAuthInit = createRequestThunk<boolean, AuthInitResponse, CommonResponse>(
	requestAuthInitActions,
	() =>
		AuthService.getAuthInit().catch((err) => {
			AuthService.revokeToken()
			return Promise.reject(err)
		})
)
